import React, { ChangeEvent, ClipboardEvent, useContext } from 'react';
import Loja from '~/components/Loja';
import { InputNumber } from '~/components/NovosInputs';
import { LojaContext } from '~/context/loja';
import { handleKeyDown } from '../../utils';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';
import { IoIosSearch } from 'react-icons/io';
import { MdOutlineCancel } from 'react-icons/md';
import { FaEraser } from 'react-icons/fa';
import {
  ButtonContainer,
  InputsTelaContainer,
  InputsTelaContent,
} from './styles';
import { useFechamentoFiscal } from '../../hooks/useFechamentoFiscal';

export const InputsTela: React.FC = () => {
  const { loja, changeLoja } = useContext(LojaContext);
  const {
    formTela,
    currentYear,
    onSearch,
    onCancel,
    onClear,
    isLojaInvalid,
    loading,
    disable,
    onChangeDisable,
  } = useFechamentoFiscal();

  const {
    setValue,
    register,
    control,
    clearErrors,
    formState: { errors },
  } = formTela;

  const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
    const { clipboardData } = event;
    const pastedText = clipboardData.getData('text');
    if (/[a-zA-Z]/.test(pastedText)) {
      const numericText = pastedText.replace(/[^0-9]/g, '');
      setValue('ano_fechamento', numericText);
      onChangeDisable({
        search: false,
        cancel: true,
        clear: false,
        store: false,
        year: false,
      });
      event.preventDefault();
    }
  };

  return (
    <InputsTelaContent>
      <InputsTelaContainer>
        <div className="div-loja">
          <Loja
            selectedLoja={loja.cod_loja}
            IsInvalid={isLojaInvalid}
            isDisabled={loading || disable.store}
            onChange={(e) => {
              changeLoja(typeof e === 'number' ? e : loja.cod_loja);
              onChangeDisable({
                search: false,
                cancel: true,
                clear: false,
                store: false,
                year: false,
              });
            }}
          />
        </div>
        <div className="div-ano">
          <InputNumber
            register={register}
            label="Ano"
            control={control}
            placeholder={`${currentYear ?? 0}`}
            isError={!!errors.ano_fechamento}
            name="ano_fechamento"
            type="number"
            min={0}
            maxLength={4}
            max={9999}
            disabled={loading || disable.year}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              clearErrors('ano_fechamento');
              if (event.target.value.length > 4) {
                event.target.value = event.target.value.slice(0, 4);
              }
              setValue('ano_fechamento', event.target.value);
              onChangeDisable({
                search: false,
                cancel: true,
                clear: false,
                store: false,
                year: false,
              });
            }}
            onPaste={handlePaste}
            onKeyDown={handleKeyDown}
          />
        </div>
      </InputsTelaContainer>
      <ButtonContainer>
        <CustomButtonNovo
          onClick={onSearch}
          label="Pesquisar"
          icon={IoIosSearch}
          disabled={loading || disable.search}
          showLoading={loading}
        />
        <CustomButtonNovo
          onClick={onCancel}
          label="Cancelar"
          icon={MdOutlineCancel}
          disabled={disable.cancel}
          variant="cancel"
        />
        <CustomButtonNovo
          onClick={onClear}
          label="Limpar"
          icon={FaEraser}
          disabled={loading || disable.clear}
          variant="clear"
        />
      </ButtonContainer>
    </InputsTelaContent>
  );
};
