import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  height: 100%;
  padding: 1.25rem;

  h3 {
    margin-top: 1.625rem;
    margin-bottom: 1.25rem;
  }

  .MuiDataGrid-selectedRowCount {
    color: transparent !important;
  }
  .row {
    --bs-gutter-x: 0 !important;
    --bs-gutter-y: 0 !important;
  }

  .super-app-theme--header {
    background: #8850bf !important;
  }

  .box-month {
    border: 0.0938rem solid #dfe1e6;
    padding: 1.25rem;
  }
`;
