import React from 'react';
import { FechamentoFiscalContent } from './FechamentoFiscalContent';
import { FechamentoFiscalProvider } from './contexts/FechamentoFiscalContext';

export const FechamentoFiscal: React.FC = () => {
  return (
    <FechamentoFiscalProvider>
      <FechamentoFiscalContent />
    </FechamentoFiscalProvider>
  );
};
