import styled from 'styled-components';

export const ContainerPadlocks = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const RowPadlocks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const MonthContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.5rem;
  gap: 0.625rem;
`;

export const MonthName = styled.div`
  font-size: 1.2rem;
  width: 6.25rem;
  user-select: none;
`;

export const IconsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;
