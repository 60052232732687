import * as yup from 'yup';

export const schema = yup.object().shape({
  des_feriado: yup.string().required(),
  num_ano: yup.object().shape({
    label: yup.string().required(),
    value: yup.number().required(),
  }),
  num_mes: yup.object().shape({
    label: yup.string().required(),
    value: yup.number().required(),
  }),
  num_dia: yup.object().shape({
    label: yup.string().required(),
    value: yup.number().required(),
  }),
  tipo_feriado: yup.object().shape({
    label: yup.string().required(),
    value: yup.number().required(),
  }),
});
