import React, { useContext, useEffect, useState, useCallback } from 'react';
import { getLastDayOfMonth, monthNames, padlocksMonths } from '../../utils';
import {
  ContainerPadlocks,
  IconsContainer,
  MonthContainer,
  MonthName,
  RowPadlocks,
} from './styles';
import { useFechamentoFiscal } from '../../hooks/useFechamentoFiscal';
import { fechamentoFiscalService } from '../../services';
import { LojaContext } from '~/context/loja';
import { toast } from 'react-toastify';

export const Padlocks: React.FC = () => {
  const { padlockStates, setPadlockStates, loading, currentYear } =
    useFechamentoFiscal();
  const { loja } = useContext(LojaContext);
  const [padlocks, setPadlocks] = useState<JSX.Element[]>([]);
  const [clicked, setClicked] = useState(false);

  const isLoading = loading || clicked;

  const handlePadlockClick = useCallback(
    async (month: string, isClosed: boolean, monthNumber: number) => {
      if (!currentYear || loading) return;

      const checkFechamento = await fechamentoFiscalService.verificaMesFechado(
        loja.cod_loja,
        currentYear,
        monthNumber,
      );

      if (checkFechamento.success) {
        setClicked(true);

        if (isClosed) {
          const checkReabertura =
            await fechamentoFiscalService.verificFechamentoReabertura(
              loja.cod_loja,
              currentYear,
              monthNumber,
            );

          if (checkReabertura.success) {
            if (checkReabertura.data) {
              setPadlockStates((prevState) => ({
                ...prevState,
                [month]: { close: !checkFechamento.data },
              }));

              await fechamentoFiscalService.destroyFechamento(
                loja.cod_loja,
                getLastDayOfMonth(monthNumber, currentYear),
              );
            } else {
              toast.warning(
                'Não é permitido reabrir um mês fiscal que tenha um mês fechado à sua frente',
              );
            }
          }
        } else {
          const res = await fechamentoFiscalService.storeFechamento(
            loja.cod_loja,
            getLastDayOfMonth(monthNumber, currentYear),
          );

          if (res.success) {
            setPadlockStates((prevState) => ({
              ...prevState,
              [month]: { close: !checkFechamento.data },
            }));
          }
        }
        setClicked(false);
      }
    },
    [currentYear, loading, loja.cod_loja, setPadlockStates],
  );

  useEffect(() => {
    const rows = padlocksMonths.reduce<JSX.Element[]>((acc, item, index) => {
      const rowIndex = Math.floor(index / 4);

      if (!acc[rowIndex]) {
        acc[rowIndex] = (
          <RowPadlocks key={rowIndex}>
            {padlocksMonths
              .slice(rowIndex * 4, rowIndex * 4 + 4)
              .map((padlock) => {
                const Icon = padlockStates[padlock.month].close
                  ? padlock.iconClosed
                  : padlock.iconOpened;
                const monthNumber =
                  monthNames.findIndex((m) => m === padlock.month) + 1;
                const isClosed = padlockStates[padlock.month].close;

                return (
                  <MonthContainer key={padlock.month}>
                    <MonthName>{padlock.month}</MonthName>
                    <IconsContainer>
                      <Icon
                        onClick={() =>
                          handlePadlockClick(
                            padlock.month,
                            isClosed,
                            monthNumber,
                          )
                        }
                        role={!isLoading ? 'button' : undefined}
                        size={28}
                        color={isClosed ? 'red' : '#A9A9A9'}
                        aria-label={`Botão ${
                          isClosed ? 'fechar' : 'abrir'
                        } fechamento fiscal`}
                        aria-disabled={isLoading}
                      />
                    </IconsContainer>
                  </MonthContainer>
                );
              })}
          </RowPadlocks>
        );
      }

      return acc;
    }, []);

    setPadlocks(rows);
  }, [handlePadlockClick, isLoading, padlockStates]);

  return <ContainerPadlocks>{padlocks}</ContainerPadlocks>;
};
