import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  FechamentoFiscalContextData,
  FechamentoFiscalProviderProps,
  PadlockState,
  UseFormFechamentoProps,
} from '../types/context';
import { useForm } from 'react-hook-form';
import { LojaContext } from '~/context/loja';
import { toast } from 'react-toastify';
import { fechamentoFiscalService } from '../services';
import { getMonthName, padlocksMonths } from '../utils';

export const FechamentoFiscalContext = createContext(
  {} as FechamentoFiscalContextData,
);

export const FechamentoFiscalProvider: React.FC<
  FechamentoFiscalProviderProps
> = ({ children }) => {
  const { loja } = useContext(LojaContext);
  const [currentYear, setCurrentYear] = useState<number | null>(null);
  const [isLojaInvalid, setIsLojaInvalid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState({
    search: true,
    cancel: false,
    clear: true,
    store: false,
    year: false,
  });
  const [padlockStates, setPadlockStates] = useState<PadlockState>(
    padlocksMonths.reduce((acc, item) => {
      acc[item.month] = { close: true };
      return acc;
    }, {} as PadlockState),
  );
  const [callSearch, setCallSearch] = useState(false);

  const {
    register,
    getValues,
    handleSubmit,
    formState,
    control,
    setValue,
    watch,
    reset,
    setFocus,
    clearErrors,
    setError,
    resetField,
  } = useForm({
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const formTela: UseFormFechamentoProps = {
    register,
    getValues,
    handleSubmit,
    formState,
    control,
    setValue,
    watch,
    reset,
    setFocus,
    clearErrors,
    setError,
    resetField,
  };

  const onChangeCurrentYear = useCallback((year: number | null) => {
    setCurrentYear(year);
  }, []);

  const onChangeDisable = useCallback(
    ({
      search,
      cancel,
      clear,
      store,
      year,
    }: {
      search: boolean;
      cancel: boolean;
      clear: boolean;
      store: boolean;
      year: boolean;
    }) => {
      setDisable({ search, cancel, clear, store, year });
    },
    [],
  );

  const onReset = useCallback(
    (resetform: 'onCancel' | 'onClear') => {
      setIsLojaInvalid(false);
      clearErrors('ano_fechamento');

      if (resetform === 'onClear') {
        const currYear = new Date().getFullYear();

        setValue('ano_fechamento', currYear);
        setCurrentYear(currYear);
        setCallSearch(true);
      } else if (resetform === 'onCancel') {
        const anoFechamento = getValues('ano_fechamento');

        setValue('ano_fechamento', anoFechamento);
        setCurrentYear(anoFechamento);
        setDisable({
          search: false,
          cancel: true,
          clear: false,
          store: false,
          year: false,
        });
      }
    },
    [clearErrors, getValues, setValue],
  );

  const onSearch = useCallback(async () => {
    if (!loja || (loja && typeof loja.cod_loja !== 'number')) {
      setIsLojaInvalid(true);
      toast.warning('Informe a loja para continuar');
    }

    if (!currentYear) setError('ano_fechamento', { type: 'required' });

    if ((!loja || (loja && typeof loja.cod_loja !== 'number')) && !currentYear)
      return toast.warning('Loja e Ano devem ser informados');

    const currYear = new Date().getFullYear();
    const anoFechamento = Number(getValues('ano_fechamento'));

    if (anoFechamento > currYear) {
      setError('ano_fechamento', { type: 'required' });
      return toast.warning(`Ano deve ser inferior ou igual a ${currYear}`);
    }

    setLoading(true);

    const status = await fechamentoFiscalService.listaFechamentoStatus(
      loja.cod_loja,
      anoFechamento,
    );

    if (status.success) {
      const fechamento = status.data.map((s) => ({
        month: getMonthName(s.dta_fechamento),
        close: s.flg_fechado,
      }));

      const updatedPadlockStates = { ...padlockStates };

      fechamento.forEach((f) => {
        updatedPadlockStates[f.month] = { close: f.close };
      });

      setPadlockStates(updatedPadlockStates);
      setCurrentYear(anoFechamento);
      setValue('ano_fechamento', anoFechamento);
      setDisable({
        search: true,
        cancel: false,
        clear: true,
        store: true,
        year: true,
      });
    }

    clearErrors('ano_fechamento');
    setIsLojaInvalid(false);
    setLoading(false);
    setCallSearch(false);
  }, [
    clearErrors,
    currentYear,
    getValues,
    loja,
    padlockStates,
    setError,
    setValue,
  ]);

  const onCancel = useCallback(() => {
    onReset('onCancel');
  }, [onReset]);

  const onClear = useCallback(() => {
    onReset('onClear');
  }, [onReset]);

  useEffect(() => {
    const currYear = new Date().getFullYear();
    setCurrentYear(currYear);
    setValue('ano_fechamento', currYear);
  }, [setValue]);

  useEffect(() => {
    const fetchData = async () => {
      await onSearch();
      setTimeout(() => {
        setDisable({
          search: true,
          cancel: false,
          clear: true,
          store: true,
          year: true,
        });
      }, 250);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await onSearch();
    };
    if (callSearch) fetchData();
  }, [callSearch]);

  return (
    <FechamentoFiscalContext.Provider
      value={{
        formTela,
        currentYear,
        isLojaInvalid,
        loading,
        padlockStates,
        disable,

        onChangeCurrentYear,
        onSearch,
        onCancel,
        onClear,
        onChangeDisable,

        setPadlockStates,
      }}
    >
      {children}
    </FechamentoFiscalContext.Provider>
  );
};
