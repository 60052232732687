import styled from 'styled-components';

export const InputsTelaContent = styled.article`
  display: flex;
  flex-direction: column;
`;

export const InputsTelaContainer = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.125rem;

  .div-loja {
    width: 85%;
  }

  .div-ano {
    width: 15%;
    margin-bottom: 0.6563rem;
  }

  @media only screen and (max-width: 800px) {
    flex-direction: column;
    .div-loja,
    .div-ano {
      width: 100%;
    }
  }
`;

export const ButtonContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 0.625rem;
  margin-top: 0.9375rem;
`;
