import api from '~/services/api';
import {
  ResponseFechamentoReaberturaProps,
  ResponseFechamentoStatusProps,
  ResponseStoreFechamento,
  ResponseVerificaMesFechadoProps,
} from '../types/services';

const listaFechamentoStatus = async (
  cod_loja: number,
  num_ano: number,
): Promise<ResponseFechamentoStatusProps> => {
  const { data } = await api.get<ResponseFechamentoStatusProps>(
    `/fechamento-fiscal/status/${cod_loja}/${num_ano}`,
  );
  return data;
};
const verificaMesFechado = async (
  cod_loja: number,
  num_ano: number,
  num_mes: number,
): Promise<ResponseVerificaMesFechadoProps> => {
  const { data } = await api.get<ResponseVerificaMesFechadoProps>(
    `/fechamento-fiscal/verifica-mes-fechado/${cod_loja}/${num_ano}/${num_mes}`,
  );
  return data;
};
const storeFechamento = async (
  cod_loja: number,
  dta_fechamento: Date,
): Promise<ResponseStoreFechamento> => {
  const { data } = await api.post<ResponseStoreFechamento>(
    '/fechamento-fiscal/store',
    {
      cod_loja,
      dta_fechamento,
    },
  );
  return data;
};
const destroyFechamento = async (
  cod_loja: number,
  dta_fechamento: Date,
): Promise<void> => {
  const { data } = await api.post<void>('/fechamento-fiscal/destroy', {
    cod_loja,
    dta_fechamento,
  });
  return data;
};
const verificFechamentoReabertura = async (
  cod_loja: number,
  num_ano: number,
  num_mes: number,
): Promise<ResponseFechamentoReaberturaProps> => {
  const { data } = await api.get<ResponseFechamentoReaberturaProps>(
    `/fechamento-fiscal/verifica-fechamento-reabertura/${cod_loja}/${num_ano}/${num_mes}`,
  );
  return data;
};

export const fechamentoFiscalService = {
  listaFechamentoStatus,
  verificaMesFechado,
  storeFechamento,
  destroyFechamento,
  verificFechamentoReabertura,
};
