import { KeyboardEvent } from 'react';
import { FaLock, FaLockOpen } from 'react-icons/fa';

export const handleKeyDown = (event: KeyboardEvent): void => {
  const forbiddenKeys = ['e', 'E', '+', '-', '.', ','];
  if (forbiddenKeys.includes(event.key)) {
    event.preventDefault();
  }
};

const createPadlockMonth = (month: string) => ({
  month,
  iconOpened: FaLockOpen,
  iconClosed: FaLock,
});

export const monthNames = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export const padlocksMonths = monthNames.map(createPadlockMonth);

export const getMonthName = (dateStr: string): string => {
  const date = new Date(dateStr);
  return monthNames[date.getUTCMonth()];
};

export const getLastDayOfMonth = (month: number, year: number): Date => {
  const date = new Date(year, month, 0);
  return date;
};
