import React from 'react';
import { Container } from './styles';
import { InputsTela } from './components/InputsTela/inex';
import Separator from '~/components/Separator';
import { Padlocks } from './components/Padlocks';

export const FechamentoFiscalContent: React.FC = () => {
  return (
    <Container>
      <h3>Fechamento Fiscal</h3>
      <InputsTela />
      <Separator labelText="Meses (Clique para fechar/reabrir)" />
      <section className="box-month">
        <Padlocks />
      </section>
    </Container>
  );
};
